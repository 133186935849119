import axios from 'axios'
import router from '../router'

export default {
    namespaced: true,
    state:{
        authenticated: false,
        user:{}
    },
    getters:{
        authenticated(state){
            return state.authenticated
        },
        user(state){
            return state.user
        }
    },
    mutations:{
      SET_AUTHENTICATED (state, value) {
          state.authenticated = value
      },
      SET_USER (state, value) {
          state.user = value
      },
    },
    actions:{
        check({commit}) {
            console.log("Checking");
        },
        setAuthenticatedTrue({commit}) {
          commit('SET_AUTHENTICATED',true);
        },
        setAuthenticatedFalse({commit}) {
          commit('SET_AUTHENTICATED',false);
        },
        forgotPassword({commit}, email) {
          return axios.post('/api/password/email',{email: email}).then(({data})=>{
              if(data && data.success) {
                return true;
              }
          }).catch(({response:{data}})=>{
              return false;
          })
        },    
        updateUserPhoneNumber({commit}, newPhoneNumber) {
          return axios.post('/api/user/update',{phone_number: newPhoneNumber}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        updateUserTagline({commit}, newTagline) {
          return axios.post('/api/user/update',{tagline: newTagline}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        updateUserLatLon({commit}, latLon) {
          let [lat, lon] = latLon.split(',');
          return axios.post('/api/user/update',{lat: lat, lon: lon}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        setUserShowTagline({commit}, showTagline) {
          return axios.post('/api/user/update',{show_tagline: showTagline}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        updateUserName({commit}, newUserName) {
          return axios.post('/api/user/update',{name: newUserName}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        setUserDailyEmailVal({commit}, sendDailyEmailsVal) {
          return axios.post('/api/user/update',{send_daily_emails: sendDailyEmailsVal}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        setUserTextReminderVal({commit}, textReminderVal) {
          return axios.post('/api/user/update',{has_sms_enabled: textReminderVal}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        updateUserTimezone({commit}, newTimezone) {
          return axios.post('/api/user/update',{timezone: newTimezone}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        updateUserOnboardingStatus({commit}, onboardingCompleted) {
          return axios.post('/api/user/update',{completed_onboarding_at: onboardingCompleted}).then(({data})=>{
              if(data && data.success) {
                if(data.user) {
                  commit('SET_USER',data.user)
                }
              }
          }).catch(({response:{data}})=>{
              commit('SET_BOARD',0)
          })
        },
        getUser({commit}){
          return axios.get('/api/user').then(({data})=>{
            
              if(data && data.user && data.user.id) {
                commit('SET_USER',data.user)
                commit('SET_BOARD', data.user.default_board_id)
                commit('SET_BOARDS', data.user.boards)
                return data.user.id;
              } else {
                commit('SET_USER',{})
                commit('SET_BOARDS',[])
                commit('SET_AUTHENTICATED',false)
              }
              
              //router.push({name: "UserHomePage"});
          }).catch(({response:{data}})=>{
              commit('SET_USER',{})
              commit('SET_AUTHENTICATED',false)
          })
        },
        logout({commit}){
          return axios.post('/logout').then(({data})=>{
              commit('SET_USER',{})
              commit('SET_AUTHENTICATED',false);
              commit('SET_BOARD', 0)
            }).catch(({response:{data}})=>{
              commit('SET_USER',{})
              commit('SET_AUTHENTICATED',false);
              commit('SET_BOARD', 0)
            })
        }
    }
}